.tooltip {
  position: relative;
}

.tooltipContainer {
  width: max-content;
  background-color: rgb(65, 65, 65);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 99999;
  display: block;
}

.tooltipContent {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

.tooltipIcon {
  margin: 12px 12px 12px 0;
}

.tooltipNumbers {
  margin-right: 8px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
}

.tooltipDifference {
  font-weight: 300;
  margin-left: 2px;
}

.campaign {
  &:hover {
    background-color: #f4f5f799 !important;
  }

  &-expanded {
    @extend .campaign;
    td {
      background-color: var(--divider-gray) !important;
    }
    &:hover {
      background-color: #e7e9f8;
    }
    & > td:nth-child(2) {
      color: var(--blue);
      font-weight: 600;
    }
  }
}

.expanded {
  position: sticky !important;
}

.adset {
  &-expanded {
    @extend .adset;
    & > td:nth-child(2) {
      font-weight: bold;
    }
  }
}

.lastAdset {
  @extend .adset;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &-expanded {
    @extend .lastAdset;
    border-radius: 0;
    .onlyAd {
      border-bottom: 10px solid var(--divider-gray);
    }
    & > td:nth-child(2) {
      font-weight: bold;
    }
  }
}

.ad {
  width: 100%;
  border-top: none;
  position: relative;
  &::before {
    content: "";
    width: calc(100% - 10px);
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
  }
  & > td {
    z-index: 1;
  }

  &-expanded {
    @extend .ad;
  }
}

.onlyAd {
  @extend .ad;

  &::before {
    content: "";

    width: calc(100% - 10px);
    position: absolute;
    z-index: 0;
    background-color: white;
    top: 0;
    bottom: 0;
    border-radius: 4px;
  }

  & > td {
    z-index: 1;
  }

  &-expanded {
    @extend .onlyAd;
  }
}

.innerAd {
  @extend .ad;

  border-bottom: 1px solid var(--divider-gray);
  > td:nth-child(1) {
    margin-left: 0px !important;
  }

  &-expanded {
    @extend .innerAd;
  }
}

.lastItem {
  @extend .ad;
  border-bottom: 10px solid var(--divider-gray) !important;
  &-expanded {
    @extend .lastItem;
  }
}

.itemRow {
  display: flex;
  align-items: center;
  gap: 5px;
}

.iconContainer {
  display: flex;
  > img {
    width: 14px;
    height: 14px;
  }
}

.nameContainer {
  min-width: 150px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.name {
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 5px;
}

.breakeven {
  color: var(--text-black);
  font-weight: 700;
}

.profit {
  font-weight: 700;
  color: var(--success);
}

.loss {
  font-weight: 700;
  color: var(--alert);
}

.campaignMobile {
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.campaignName {
  max-width: 90px;
}

.chevronContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.stickyName {
  position: sticky;
  top: -20px;
  background: white;
  border-radius: 0;
  z-index: 3;
  min-height: 75px;
  min-height: 60px;
  height: 60px;
  padding: 0 12px;
  justify-content: center;
}

.formattedCellValue {
  font-size: 13px;
}
.header {
  position: relative;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHeader1,
.tableHeader2 {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  position: relative;
  background-color: #25c1e8;
  border-radius: 5px;
}

.tableHeader2 {
  background-color: #6fca81;
}

.scrollIndicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  font-size: 24px;
}

.scrollIndicator.left {
  left: 10px;
}

.scrollIndicator.right {
  right: 10px;
}

.tableContainer {
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  padding-bottom: 10px;
}
