.container {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 2px 6px rgb(34 34 34 / 4%);
  @media screen and (max-width: 480px) {
    flex: 0;
  }
}

.contentSegment {
  padding: 18px 24px;
  @media screen and (max-width: 480px) {
    padding: 24px 20px;
  }
  flex: 1;
}

.headerSegment {
  min-height: 80px;
  height: 80px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 480px) {
    height: unset;
    min-height: unset;
    align-items: flex-start;
    padding: 24px 20px;
    h3 {
      font: normal normal bold 16px/19px Metropolis, sans-serif;
    }
  }
  h3 {
    height: auto;
    line-height: 1;
  }
}

.headerSegmentContent {
  flex: 1;
  min-height: 32px;
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
}

.fullWidth {
  width: 100%;
}

.noFlex {
  flex: unset;
}
